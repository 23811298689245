import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin : false,
    userId : '',
    currentData : {},
    currentDataLlist : {},
    currentDataLlistDefault : {},
    gotListState : 0,
    headers: [
      // { text: 'checkBox', sortable: false, value: 'checkBox' },
      { text: '構造物種類', value: 'kouzoubutsusyurui' },
      { text: '構造物名', value: 'kouzoubutsumei' },
      { text: '路線名', value: 'rosenmei' },
      { text: '所在地', value: 'syozaichi' },
      { text: '管理者名', value: 'kanrisyamei' },
      { text: '対象部材', value: 'hosyuutaisyoubuzai' },
      { text: 'データ更新日時', value: 'dataupdated' }
    ],
    cols :['構造物種類',	'構造物名',	'路線名',	'所在地',	'管理者名', '対象部材', 'データ更新日時'],
    msalConfig: {
      auth: {
        clientId: '64c1d8bd-2481-4331-8ce2-3d727001a346',
        authority: 'https://login.microsoftonline.com/55e0718a-8e26-4420-9728-63bee4e43df8',
        redirectUri: `${process.env.VUE_APP_REDIRECT_URI}`,
      },
      cache: {
        cacheLocation: 'localStorage',
        // storeAuthStateInCookie: true,
      },
    },
    accessToken: '',
    isAdmin: false,
    account: undefined,
    sortedHeader: 'dataupdated',
    sortedDesc: true,
    snackbar: false,
    snackbarText: '',
    isSelectMode: false,
    deleteItems: []
  },
  mutations: {
    // auth(state, user) {
    //   state.isLogin = true;
    //   state.userId = user;
    // },
    setData(state, items) {
      state.currentData = items;
    },
    setDataList(state, itemsList) {
      state.currentDataLlist = itemsList;
    },
    setDefaultDataList(state, itemsList) {
      state.currentDataLlistDefault = itemsList;
    },
    setGotListState(state, num) {
      state.gotListState = num;
    },
    setHeaders(state, content) {
      state.headers = content;
    },
    setCols(state, col) {
      state.cols = col;
    },
    setAccessToken(state, token){
      state.accessToken = token;
    },
    setAdmin(state, bool){
      state.isAdmin = bool;
    },
    setAccount(state, account) {
      state.account = account
    },
    setSortHeader(state, item) {
      state.sortedHeader = item
    },
    setSortDesc(state, bool) {
      state.sortedDesc = bool
    },
    openSnackbar(state, text) {
      state.snackbar = true
      state.snackbarText = text
    },
    setMode(state, bool) {
      state.isSelectMode = bool
    },
    setDeleteItems(state, items) {
      state.deleteItems = items
    },
  },
  actions: {
    // fetch(context, user) {
    //   context.commit('auth', user);
    // },
    setCurrentData(context, items) {
      context.commit('setData', items)
    },
    setCurrentDataList(context, itemsList) {
      context.commit('setDataList', itemsList)
    },
    setCurrentDefaultDataList(context, itemsList) {
      context.commit('setDefaultDataList', itemsList)
    },
    setCurrentGotListState(context, num) {
      context.commit('setGotListState', num)
    },
    setCurrentHeaders(context, content) {
      context.commit('setHeaders', content)
    },
    setCurrentCols(context, col) {
      context.commit('setCols', col)
    },
    setIsAdmin(context, bool) {
      context.commit('setAdmin', bool)
    },
    setCurrentSortHeader(context, item) {
      context.commit('setSortHeader', item)
    },
    setCurrentSortDesc(context, bool) {
      context.commit('setSortDesc', bool)
    },
    setSnackbar(context, text) {
      context.commit('openSnackbar', text)
    },
    changeMode(context, bool) {
      context.commit('setMode', bool)
    },
    updateDeleteItems(context, items) {
      context.commit('setDeleteItems', items)
    },
  },
  modules: {},
  plugins :[createPersistedState(
    {
      key: 'rpApp',
      paths: ['currentData','headers','cols','isAdmin'],
      storage: window.sessionStorage,
    },
    {
      key: 'rpApp',
      paths: ['msalConfig'],
      storage: window.localStorage,
    },
  )]
});

// export default store