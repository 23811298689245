import Vue from 'vue';
//import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

export default new Vuetify({
  // theme: { dark: false },
  theme: {
    dark: false,
    themes: {
      light: {
        background: "#E0E0E0"
      },
      dark: {
        background: "#69325f",
        background2: "#474759"
      }
    }
  }
});
