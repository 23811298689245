import Vue from 'vue'
import App from './App.vue'
// import Home from './components/Home.vue'
import vuetify from './plugins/vuetify';
import router from './router.js'
import axios from 'axios'
import store from '@/store'
import utils from './mixins/utils'

Vue.config.productionTip = false

Vue.use({
  install (Vue) {
    Vue.prototype.$axios = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}`
    })
  }
})
Vue.prototype.$msalInstance = {};

Vue.mixin(utils)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')