<template>
	<v-app :style="{ background: $vuetify.theme.themes.light.background }">
		<div v-if="$store.state.account">
      <div>
        <v-navigation-drawer app v-model="drawer" clipped dark color="indigo darken-2"> 
          <v-container>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title grey--text text--lighten-4">
                  Menu
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense nav>
              <v-list-item
                v-for="nav_list in nav_lists_admin"
                :key="nav_list.name"
                :to="nav_list.link"
                @click="selected"
              >
                <v-list-item-icon>
                  <v-icon>{{ nav_list.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
        </v-navigation-drawer>
        <v-app-bar app clipped-left dark color="indigo darken-4">
          <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
          <v-toolbar-title class="title grey--text text--lighten-3">
            AI診断士</v-toolbar-title>
          <v-spacer></v-spacer>

          <div class="text-center" v-if="$store.state.account">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
              <v-btn style="text-transform: none" text class="text-subtitle-1" v-on="on" v-bind="attrs">{{ $store.state.account.username }}
                <pre>  </pre>
                <v-icon>mdi-account-circle</v-icon>
              </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="$store.state.isAdmin">
                      {{ $store.state.account.name }} (管理者)
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{ $store.state.account.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle> {{ $store.state.account.username }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-btn text @click="SignOut">
                      <v-icon>mdi-logout</v-icon>
                      サインアウト</v-btn>
                  </v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>
          </div>

          
        </v-app-bar>
        <v-main>
          <v-container fluid>
          <router-view></router-view>
          <v-snackbar
            v-model="$store.state.snackbar"
            :timeout="4000"
            :top="true"
            :right="true"
          >
            {{ $store.state.snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="$store.state.snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>


          </v-container>
        </v-main>
        <v-footer app  dark color="indigo darken-4">AI診断士</v-footer>
      </div>
		</div>
    <div v-else>
			<v-container>
			</v-container>
		</div>
	</v-app>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';

export default {
	name: 'Home',
	data () {
		return {
			drawer: null,
			nav_lists_admin: [
				{name: 'アップロード', icon: 'mdi-cloud-upload', link: '/upload'},
				{name: '構造物一覧', icon: 'mdi-format-list-bulleted', link: '/buildinglist'}
			],
      nav_lists: [
				{name: '構造物一覧', icon: 'mdi-format-list-bulleted', link: '/buildinglist'}
			],
			account: undefined,
      adminId: 'b4d5b59c-d80a-4e7e-ba7c-ba6a85c3318c'
		}
	},
	methods: {
		async selected () {
			// console.log('clicked')
		},
    
    async signInRedirect() {
      // console.log("signInRedirect()")
      return await this.$msalInstance.loginRedirect({scopes: ['api://4d6ffc36-1f8c-4858-9b01-225da2a1af5d/read',]})
    },

    handleResponse(resp) {
      // console.log("handleResponse")
      if (resp !== null) {
        this.account = resp.account;
        this.$store.commit('setAccount', this.account);

        const myAccounts = this.$msalInstance.getAllAccounts();
        this.account = myAccounts[0];
        const groups = this.account["idTokenClaims"]["groups"]
        if (groups) {
          for ( let group of groups ) {
            if ( group == this.adminId ) {
              this.$store.dispatch("setIsAdmin", true);
              break
            }
          }
        } else {
          this.$store.dispatch("setIsAdmin", false);
        }

      } else {
        const currentAccounts = this.$msalInstance.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
          
          this.signInRedirect();

      } else if (currentAccounts.length > 1) {
            // Add choose account code here
        }
      }
    },

    async SignOut() {
      await this.$msalInstance
        .logoutRedirect({
					account: this.$store.state.account,
					postLogoutRedirectUri: "/",
				})
        .then(() => {
          sessionStorage.clear()
        })
        .catch(error => {
          console.error(error);
        });
    }
	},
	async created() {
    console.log("created()")
		this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    this.$msalInstance.handleRedirectPromise().then(this.handleResponse).catch((error) => {
      console.log(error);
    });
	},
  mounted() {
    console.log("mounted()")
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    const groups = this.account["idTokenClaims"]["groups"]
    this.$store.commit('setAccount', this.account);
    if (groups) {
      for ( let group of groups ) {
        if ( group == this.adminId ) {
          this.$store.dispatch("setIsAdmin", true);
          break
        }
      }
    } else {
      this.$store.dispatch("setIsAdmin", false);
    }
  },
}
</script>

<style>
  input[type='text'] {
    margin-bottom: 20px;
    padding: 10px;
  }

  img {
	display: block;
	max-width:1000px;
	max-height:1000px;
	width: auto;
	height: auto;
}
</style>