import Vue from 'vue'
import Router from "vue-router"
// import Home from "./components/Home.vue"
// import Page_2 from "./components/Page2.vue"
// import Login from "./components/Login.vue"
// import Store from '@/store/index.js'
//import Upload from "./components/Upload.vue"

// import tmp from "./components/tmp.vue"

Vue.use(Router)

export default new Router({
//const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/', 
      name: 'home',
      component: () => import('./components/BuildingList.vue'),
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: '/upload', 
      name: 'upload',    
      component: () => import('./components/Upload.vue'),
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: '/buildinglist', 
      name: 'buildinglist',    
      component: () => import('./components/BuildingList.vue'),
      props:true
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: '/buildingdetail', 
      name: 'buildingdetail',    
      component: () => import('./components/BuildingDetail.vue'),
      props: true
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: '*',   
      component: () => import('./components/NotFound.vue'),
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
  } else {
      return { x: 0, y: 0 }
  }

  }
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!Store.state.isLogin) {
//       next({
//         path: '/Login',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

//export default router