import { PublicClientApplication } from '@azure/msal-browser';
export default {
	// data () {
  //   return {
  //     account: undefined
  //   }
  // },
  methods: {
    async getAccessToken(account){
      let request = {
				// acount: this.account,
        scopes: ['api://4d6ffc36-1f8c-4858-9b01-225da2a1af5d/read',],
				acount: account,
				// scopes: ['user.read'],
      };
      const msalInstance = new PublicClientApplication(
        this.$store.state.msalConfig,
      );
			msalInstance.setActiveAccount(account)
      // console.log(account)
      try {
        // console.log('acquireTokenSilent')
        let tokenResponse = await msalInstance.acquireTokenSilent(request);
        this.$store.commit('setAccessToken', tokenResponse.accessToken);
      } catch (error) {
				console.log(error)
				console.error( 'Silent token acquisition failed. Using interactive mode' );

        // msalInstance.setActiveAccount(null)
        try {
          let tokenResponse = await msalInstance.acquireTokenPopup(request);
          this.$store.commit('setAccessToken',tokenResponse.accessToken);
        } catch {
          let tokenResponse = await msalInstance.acquireTokenRedirect(request);
          this.$store.commit('setAccessToken',tokenResponse.accessToken);
        }

          // const accountsBefore = $msalInstance.getAllAccounts();
          // msalInstance.removeAccounts()
          // await msalInstance.loginRedirect({scopes: ['api://4d6ffc36-1f8c-4858-9b01-225da2a1af5d/read',]})

          // const accounts = this.$msalInstance.getAllAccounts();
          // let account = accounts[0];
          // this.$store.commit('setAccount', account);
        
      }
    }
  }
}